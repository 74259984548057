import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import { exportStore } from "@/store";

const routes: Array<RouteRecordRaw> = [
  // PC端
  {
    path: '/',
    name: "京宁富华首页",
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/product',
    name: "产品中心",
    component: () => import('@/views/product/index.vue'),
  },
  {
    path: '/solution',
    name: "解决方案",
    component: () => import('@/views/solution/index.vue'),
  },
  {
    path: '/successCase',
    name: "客户案例",
    component: () => import('@/views/successCase/index.vue'),
  },
  {
    path: '/news',
    name: "新闻资讯",
    component: () => import('@/views/news/index.vue'),
  },
  {
    path: '/aboutUs',
    name: "关于京宁富华",
    component: () => import('@/views/aboutUs/index.vue'),
  },
  {
    path: '/contactUs',
    name: "联系我们",
    component: () => import('@/views/contactUs/index.vue'),
  },


  // 单独大屏页面
  // {
  //   path: '/test',
  //   name: "测试",
  //   component: () => import('@/views/test/index.vue'),
  // },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// let pinia = exportStore();

router.beforeEach((to, from, next) => {
  next();
  // if(from.path == '/product'){
  //   pinia.toProductDetail == '0';
  // }
})

export default router
