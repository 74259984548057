import { defineStore } from 'pinia'

export const exportStore = defineStore({
  id: 'shi',
  state: () => {
    return {
      // homeAnchor: void 0 as any, //首页锚点
      solutionAnchor: void 0, //解决方案页面锚点
      successCaseAnchor: void 0, //成功案例页面锚点
      aboutUsAnchor: void 0, // 关于我们页面锚点
      varable: true, //头部切换锚点位置变量（方便用于监听） 

      headerShow: true, //头部和底部是否显示
      solutionVarable: true, //解决方案页面切换锚点位置变量（方便用于监听） 
      // homeVarable: true, //首页页面切换锚点位置变量（方便用于监听） 

      // 首页跳转至产品页时进入详情页
      // 0表示不进入详情页,非0则进入详情页
      // eg: 1.1整数表示产品分类,小数表示每个产品id
      toProductDetail: '0',
      // 首页跳转至新闻页时进入详情页
      // 0表示不进入详情页,非0则进入详情页
      // eg: 1.1整数表示新闻分类,小数表示每个新闻id
      toNewsDetail: '0',
    }
  },
  getters: {},
  actions: {}
})
