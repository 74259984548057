
import { defineComponent, reactive, onMounted, getCurrentInstance } from "vue";
import Header from "@/components/header.vue";
import PhoneHeader from "@/components/phoneHeader.vue";
import Footer from "@/components/footer.vue";
export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
    PhoneHeader
  },
  setup() {
    // 页面字体不能被选中
    let _body: any = document.querySelector("body")
    _body.addEventListener("selectstart", function (e) {
      e.preventDefault()
    })
    // 页面适配
    let style;
    if (document.body.clientWidth > 1080) {
      style = reactive({
        width: 1920,
        height: 937,
        transform: "scaleY(1) scaleX(1) translate(-50%, -50%)"
      })
    } else {
      style = reactive({
        width: 750,
        height: 1334,
        transform: "scaleY(1) scaleX(1) translate(-50%, -50%)"
      })
    }
    function setScale() {
      const x = window.innerWidth / style.width;
      const y = window.innerHeight / style.height;
      style.transform = `scaleY(${y}) scaleX(${x}) translate(-50%, -50%)`;
    }
    onMounted(() => {
      let { proxy } = getCurrentInstance() as any;
      proxy.setScale();
      /*窗口改变事件*/
      window.onresize = function () {
        proxy.setScale();
      };
    })
    return {
      style,
      setScale
    }
  },
});
