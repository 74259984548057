
import { reactive, defineComponent, watch, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { exportStore } from "@/store";
export default defineComponent({
  name: 'Header',
  setup() {
    let pinia = exportStore()
    // 路由列表
    let menuList = reactive([
      {
        name: "景宁富华移动端首页",
        path: "/phoneHome"
      },
      {
        name: "产品中心",
        path: "/phoneProduct"
      },
      {
        name: "解决方案",
        path: "/phoneSolution"
      },
      {
        name: "客户案例",
        path: "/phoneSuccessCase"
      },
      {
        name: "新闻资讯",
        path: "/phoneNews"
      },
      {
        name: "关于京宁富华",
        path: "/phoneAboutUs"
      },

    ])
    const router = useRouter();
    // 菜单点击回调
    function menuClick() {
      // pinia.varable = !pinia.varable
      // pinia.homeAnchor = void 0 //首页锚点
      pinia.solutionAnchor = void 0 //解决方案页面锚点
      pinia.successCaseAnchor = void 0 //成功案例页面锚点
      pinia.aboutUsAnchor = void 0 // 关于我们页面锚点
    }
    // 锚点函数
    function anchor(index) {
      var items = document.querySelectorAll(".headrer_describe");
      for (var i = 0; i < items.length; i++) {
        if (index === i) {
          items[i].scrollIntoView({
            block: "start",//默认跳转到顶部
            behavior: "smooth"//滚动的速度
          });
        }
      }
    }
    watch(
      () => pinia.varable,
      () => {
        anchor(0) //返回顶部
      },
      {
        deep: true
      }
    )
    return {
      menuList,//路由列表
      router,
      menuClick,
    }
  }
})
