import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68371356"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pc" }
const _hoisted_2 = { class: "appContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    style: _normalizeStyle({ 'width': `${_ctx.style.width}px`, 'height': `${_ctx.style.height}px`, 'transform': `${_ctx.style.transform}` })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_Footer)
    ])
  ], 4))
}