
import { useRouter } from 'vue-router';
import { exportStore } from "@/store";
export default {
  name: 'Footer',
  setup() {
    const pinia = exportStore()
    // 实例化路由
    let router = useRouter()

    // 点击跳转到产品中心页面
    function goProduct() {
      pinia.varable = !pinia.varable;
      router.push('/product')
    }
    // 点击跳转到成功案例页面
    function goCase() {
      pinia.varable = !pinia.varable;
      router.push('/successCase')
    }
    // 点击跳转到新闻资讯页面
    function goNews() {
      pinia.varable = !pinia.varable;
      router.push('/news')
    }
    // 在线留言
    function onlineMessage() {

    }
    // 站点地图（联系我们）
    function gotoContect() {
      pinia.varable = !pinia.varable;
      router.push('/contactUs')
    }
    // 点击跳转到首页
    function goHome() {
      pinia.varable = !pinia.varable
      router.push('/')
    }
    return {
      goProduct,
      goCase,
      goNews,
      onlineMessage,
      gotoContect,
      goHome,
      pinia
    }
  }
}
